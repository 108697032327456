<template>
  <div class="row mx-1 mb-2 p-2 bg-white shadow">
    <div class="col-3 my-auto">{{ vertrag.titel }}</div>
    <div class="col-3 my-auto">{{ vertragspartei1 }}</div>
    <div class="col-4 my-auto">{{ vertragspartei2 }}</div>
    <div class="col-1 my-auto">{{ dateToDateConverter(vertrag.adate) }}</div>
    <div class="col-1 my-auto d-flex justify-content-end">
      <router-link :to="{ name: 'DetailsVertrag', query: { vertragid: vertrag.id, guid: vertrag.personaldatenGuid } }" class="btn btn-primary btn-sm"
        ><font-awesome-icon icon="magnifying-glass-plus"
      /></router-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { PropType, computed } from 'vue';
  import { useDateConverter } from '@/utils/date_converter';

  import { Vertrag } from '@/models/VertragModels';

  const { vertrag } = defineProps({
    vertrag: {
      type: Object as PropType<Vertrag>,
      required: true,
    },
  });

  const { dateToDateConverter } = useDateConverter();

  const vertragspartei1 = computed(() => {
    let data = '';

    if (vertrag.vertragspartei1 == null) {
      return 'unbekannt';
    } else if (vertrag.vertragspartei1.unternehmen != '') {
      data += vertrag.vertragspartei1.unternehmen + ' | ';
    }

    data += vertrag.vertragspartei1.vorname + ' ' + vertrag.vertragspartei1.name;
    return data;
  });

  const vertragspartei2 = computed(() => {
    let data = '';

    if (vertrag.vertragspartei2 == null) {
      return 'unbekannt';
    } else if (vertrag.vertragspartei2.unternehmen != '') {
      data += vertrag.vertragspartei2.unternehmen + ' | ';
    } else if (vertrag.personaldatenGuid != '') {
      data += vertrag.personaldatenGuid + ' | ';
    }

    data += vertrag.vertragspartei2.vorname + ' ' + vertrag.vertragspartei2.name;
    return data;
  });
</script>
