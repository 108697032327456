<template>
  <div v-if="editor != null">
    <div class="control-group d-flex mb-3">
      <!-- Undo/Redo -->
      <div class="btn-group" role="group" aria-label="undo-redo">
        <button
          type="button"
          @click="editor.chain().focus().undo().run()"
          :disabled="!editor.can().chain().focus().undo().run()"
          class="btn btn-outline-secondary"
        >
          <font-awesome-icon icon="arrow-rotate-left" />
        </button>

        <button
          type="button"
          @click="editor.chain().focus().redo().run()"
          :disabled="!editor.can().chain().focus().redo().run()"
          class="btn btn-outline-secondary"
        >
          <font-awesome-icon icon="arrow-rotate-right" />
        </button>
      </div>

      <div class="border-start border-2 mx-2"></div>

      <!-- Text decoration -->
      <div class="btn-group" role="group" aria-label="Text decoration">
        <button
          type="button"
          @click="editor.chain().focus().toggleBold().run()"
          :disabled="!editor.can().chain().focus().toggleBold().run()"
          class="btn"
          :class="[editor.isActive('bold') ? 'btn-secondary' : 'btn-outline-secondary']"
        >
          <font-awesome-icon icon="bold" />
        </button>

        <button
          type="button"
          @click="editor.chain().focus().toggleItalic().run()"
          :disabled="!editor.can().chain().focus().toggleItalic().run()"
          class="btn"
          :class="[editor.isActive('italic') ? 'btn-secondary' : 'btn-outline-secondary']"
        >
          <font-awesome-icon icon="italic" />
        </button>

        <button
          type="button"
          @click="editor.chain().focus().toggleUnderline().run()"
          :disabled="!editor.can().chain().focus().toggleUnderline().run()"
          class="btn"
          :class="[editor.isActive('underline') ? 'btn-secondary' : 'btn-outline-secondary']"
        >
          <font-awesome-icon icon="underline" />
        </button>

        <button
          type="button"
          @click="editor.chain().focus().toggleStrike().run()"
          :disabled="!editor.can().chain().focus().toggleStrike().run()"
          class="btn"
          :class="[editor.isActive('strike') ? 'btn-secondary' : 'btn-outline-secondary']"
        >
          <font-awesome-icon icon="strikethrough" />
        </button>
      </div>

      <div class="btn-group ms-2" role="group" aria-label="Text sub/super">
        <button
          type="button"
          @click="editor.chain().focus().toggleSubscript().run()"
          :disabled="!editor.can().chain().focus().toggleSubscript().run()"
          class="btn"
          :class="[editor.isActive('subscript') ? 'btn-secondary' : 'btn-outline-secondary']"
        >
          <font-awesome-icon icon="subscript" />
        </button>

        <button
          type="button"
          @click="editor.chain().focus().toggleSuperscript().run()"
          :disabled="!editor.can().chain().focus().toggleSuperscript().run()"
          class="btn"
          :class="[editor.isActive('superscript') ? 'btn-secondary' : 'btn-outline-secondary']"
        >
          <font-awesome-icon icon="superscript" />
        </button>
      </div>

      <div class="border-start border-2 mx-2"></div>

      <!-- Lists -->
      <div class="btn-group" role="group" aria-label="Lists">
        <button
          type="button"
          @click="editor.chain().focus().toggleOrderedList().run()"
          :disabled="!editor.can().chain().focus().toggleOrderedList().run()"
          class="btn"
          :class="[editor.isActive('orderedList') ? 'btn-secondary' : 'btn-outline-secondary']"
        >
          <font-awesome-icon icon="list-ol" />
        </button>

        <button
          type="button"
          @click="editor.chain().focus().toggleBulletList().run()"
          :disabled="!editor.can().chain().focus().toggleBulletList().run()"
          class="btn"
          :class="[editor.isActive('bulletList') ? 'btn-secondary' : 'btn-outline-secondary']"
        >
          <font-awesome-icon icon="list-ul" />
        </button>
      </div>

      <div class="border-start border-2 mx-2"></div>

      <!-- Text alignment -->
      <div class="btn-group" role="group" aria-label="Text alignment">
        <button
          type="button"
          @click="editor.chain().focus().setTextAlign('left').run()"
          class="btn"
          :class="[editor.isActive({ textAlign: 'left' }) ? 'btn-secondary' : 'btn-outline-secondary']"
        >
          <font-awesome-icon icon="align-left" />
        </button>

        <button
          type="button"
          @click="editor.chain().focus().setTextAlign('center').run()"
          class="btn"
          :class="[editor.isActive({ textAlign: 'center' }) ? 'btn-secondary' : 'btn-outline-secondary']"
        >
          <font-awesome-icon icon="align-center" />
        </button>

        <button
          type="button"
          @click="editor.chain().focus().setTextAlign('right').run()"
          class="btn"
          :class="[editor.isActive({ textAlign: 'right' }) ? 'btn-secondary' : 'btn-outline-secondary']"
        >
          <font-awesome-icon icon="align-right" />
        </button>
        <button
          type="button"
          @click="editor.chain().focus().setTextAlign('justify').run()"
          class="btn"
          :class="[editor.isActive({ textAlign: 'justify' }) ? 'btn-secondary' : 'btn-outline-secondary']"
        >
          <font-awesome-icon icon="align-justify" />
        </button>
      </div>

      <div class="border-start border-2 mx-2"></div>

      <button
        type="button"
        class="btn btn-outline-secondary"
        @click="
          editor.chain().focus().unsetAllMarks().run();
          editor.chain().focus().clearNodes().run();
        "
      >
        <font-awesome-icon icon="text-slash" />
      </button>

      <div class="border-start border-2 mx-2"></div>
    </div>

    <div class="bg-white editor-container rounded border p-3">
      <editor-content :editor="editor" />
    </div>
  </div>
</template>

<script lang="ts" setup>
  //#region Imports
  import { StarterKit } from '@tiptap/starter-kit';
  import Highlight from '@tiptap/extension-highlight';
  import TextAlign from '@tiptap/extension-text-align';
  import { Color } from '@tiptap/extension-color';
  import ListItem from '@tiptap/extension-list-item';
  import Subscript from '@tiptap/extension-subscript';
  import Superscript from '@tiptap/extension-superscript';
  import TextStyle from '@tiptap/extension-text-style';
  import Underline from '@tiptap/extension-underline';
  import { useEditor, EditorContent } from '@tiptap/vue-3';
  //#endregion

  const props = defineProps({
    modelValue: {
      type: String,
      default: '',
    },
  });

  const emit = defineEmits(['update:modelValue']);

  // Editor initialisieren
  const editor = useEditor({
    extensions: [
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      TextStyle,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Underline,
      Highlight,
      Subscript,
      Superscript,
      StarterKit, // StarterKit enthält grundlegende Funktionen wie bold, italic, usw.
    ],
    content: props.modelValue || '', // Initialen Content setzen
    onUpdate: ({ editor }) => {
      // Sobald der Inhalt aktualisiert wird, wird das modelValue ausgegeben
      emit('update:modelValue', editor.getHTML());
    },
  });

  // function copyHTML() {
  //   const content = editor.value?.getHTML() || 'kein Inhalt';
  //   navigator.clipboard.writeText(content);
  // }

  // function copyJSON() {
  //   const content = JSON.stringify(editor.value?.getJSON()) || 'kein Inhalt';
  //   console.log(content);

  //   navigator.clipboard.writeText(content);
  // }
</script>

<style lang="scss">
  .editor-container {
    min-height: 200px;
  }

  .ProseMirror:focus {
    outline: none;
  }

  /* Basic editor styles */
  .tiptap {
    :first-child {
      margin-top: 0;
    }

    /* List styles */
    ul,
    ol {
      padding: 0 1rem;
      margin: 1.25rem 1rem 1.25rem 0.4rem;

      li p {
        margin-top: 0.25em;
        margin-bottom: 0.25em;
      }
    }

    /* Heading styles */
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.1;
      margin-top: 2.5rem;
      text-wrap: pretty;
    }

    h1,
    h2 {
      margin-top: 3.5rem;
      margin-bottom: 1.5rem;
    }

    h1 {
      font-size: 1.4rem;
    }

    h2 {
      font-size: 1.2rem;
    }

    h3 {
      font-size: 1.1rem;
    }

    h4,
    h5,
    h6 {
      font-size: 1rem;
    }

    /* Code and preformatted text styles */
    code {
      background-color: var(--purple-light);
      border-radius: 0.4rem;
      color: var(--black);
      font-size: 0.85rem;
      padding: 0.25em 0.3em;
    }

    pre {
      background: var(--black);
      border-radius: 0.5rem;
      color: var(--white);
      font-family: 'JetBrainsMono', monospace;
      margin: 1.5rem 0;
      padding: 0.75rem 1rem;

      code {
        background: none;
        color: inherit;
        font-size: 0.8rem;
        padding: 0;
      }
    }

    mark {
      background-color: #faf594;
      border-radius: 0.4rem;
      box-decoration-break: clone;
      padding: 0.1rem 0.3rem;
    }

    blockquote {
      border-left: 3px solid var(--gray-3);
      margin: 1.5rem 0;
      padding-left: 1rem;
    }

    hr {
      border: none;
      border-top: 1px solid var(--gray-2);
      margin: 2rem 0;
    }
  }
</style>
