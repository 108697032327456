<template>
  <div class="bg-white editor-container">
    <div ref="editorRef"></div>
  </div>

  <!-- {{ editor }} -->
</template>

<script lang="ts" setup>
  //#region Imports
  import { onMounted, ref } from 'vue';

  import Quill from 'quill';
  import 'quill/dist/quill.core.css';
  import 'quill/dist/quill.snow.css';
  import { Delta } from 'quill/core';
  import Embed from 'quill/blots/embed';
  //#endregion

  //#region Custom Breaker Blot
  class Breaker extends Embed {
    static tagName = 'br';
    static blotName = 'breaker';
  }

  Quill.register(Breaker);
  //#endregion

  const props = defineProps({
    modelValue: {
      type: String,
      default: '',
    },
  });

  const emit = defineEmits(['update:modelValue']);

  let editor: Quill | null = null;
  const editorRef = ref<HTMLDivElement | null>(null);

  const toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    // [{ 'font': [] }], // Add font dropdown here
    // [{ 'size': []}],
    // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    // [{ 'font': [] }],
    // ['blockquote'],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    // ['image'],

    // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    // [{ 'direction': 'rtl' }],                         // text direction

    [{ align: [] }],

    ['clean'], // remove formatting button
  ];

  const keyboardBindings = {
    // Handle Shift + Enter to insert a line break (without creating a new paragraph)
    'shift+enter': {
      key: 'Enter', // Enter key
      shiftKey: true,
      handler: function (range: any, context: any) {
        console.log(range, context);
        console.log('test');
        editor?.insertEmbed(range.index, 'breaker', true, Quill.sources.USER);
        editor?.setSelection(range.index + 1, Quill.sources.SILENT);
        return false;
      },
    },
    // Optional: Standard-Enter zum Erstellen eines neuen Absatzes
    enter: {
      key: 'Enter', // Enter-Taste
      handler: function () {
        return true; // Standardverhalten beibehalten
      },
    },
  };

  function sanitizeHTML(htmlString: string): string {
    // Regular expression to remove <script> tags and JavaScript code
    return htmlString.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');
  }

  function convertHtmlToDelta(html: string): Delta | undefined {
    if (editor != null) {
      return editor.clipboard.convert({ html: html });
    }

    return undefined;
  }

  onMounted(async () => {
    if (editorRef.value) {
      editor = new Quill(editorRef.value, {
        modules: {
          toolbar: toolbarOptions,
          keyboard: {
            bindings: keyboardBindings,
          },
        },
        theme: 'snow',
      });

      // Set the initial content
      if (props.modelValue) {
        const value = convertHtmlToDelta(sanitizeHTML(props.modelValue));
        if (value) {
          editor.setContents(value, 'silent');
        }
      }

      // Emit updates to modelValue when content changes
      editor.on('text-change', () => {
        const semanticHTML = editor?.getSemanticHTML();
        emit('update:modelValue', sanitizeHTML(semanticHTML || ''));
      });
    }
  });
</script>

<style>
  .ql-container .ql-editor {
    min-height: 200px !important;
  }

  .ql-container .ql-editor p {
    margin-bottom: 8px;
  }
</style>
