import { RootState } from '@/store';
import { ActionContext, ActionTree } from 'vuex';
import { Mutations, MutationTypes } from './mutations';
import { State } from './state';
import ApiService from '@/services/ApiService';

import { Actions as CommonActions, ActionTypes as CommonActionTypes } from '../Common/actions';

import { Druckvorlage, CreateDruckvorlageRequest, DownloadDruckvorlageRequest } from '@/models/DruckvorlageModels';

export enum ActionTypes {
  GetDruckvorlagen = 'GETDRUCKVORLAGEN',
  DownloadDruckvorlageById = 'DOWNLOADDRUCKVORLAGEBYID',
  CreateDruckvorlage = 'CREATEDRUCKVORLAGE',
  TestDruckvorlage = 'TESTDRUCKVORLAGE',
  UpdateDruckvorlage = 'UPDATEDRUCKVORLAGE',
  DeleteDruckvorlage = 'DELETEDRUCKVORLAGE',
}

type ActionArguments = Omit<ActionContext<State, RootState>, 'commit'> & {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
};

export type Actions = {
  [ActionTypes.GetDruckvorlagen](context: ActionArguments): Promise<void>;
  [ActionTypes.DownloadDruckvorlageById](context: ActionArguments, data: DownloadDruckvorlageRequest): Promise<void>;
  [ActionTypes.CreateDruckvorlage](context: ActionArguments, data: CreateDruckvorlageRequest): Promise<void>;
  [ActionTypes.TestDruckvorlage](context: ActionArguments, data: CreateDruckvorlageRequest): Promise<void>;
  [ActionTypes.UpdateDruckvorlage](context: ActionArguments, data: CreateDruckvorlageRequest): Promise<void>;
  [ActionTypes.DeleteDruckvorlage](context: ActionArguments, id: number): Promise<void>;
};

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GetDruckvorlagen]({ commit, dispatch, rootGetters }) {
    const status = rootGetters.status;
    status.getDruckvorlageLoading = true;
    status.druckvorlageErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    commit(MutationTypes.SetDruckvorlagen, new Array<Druckvorlage>());

    return ApiService.getAllDruckvorlagen()
      .then((res) => {
        const data: Array<Druckvorlage> = res.data;
        commit(MutationTypes.SetDruckvorlagen, data);
      })
      .catch((error) => {
        status.druckvorlageErrorMsg = error.response.data.detail;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getDruckvorlageLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.DownloadDruckvorlageById]({ commit, dispatch, rootGetters }, data) {
    const status = rootGetters.status;
    status.getDruckvorlageLoading = true;
    status.druckvorlageErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.downloadDruckvorlageById(data.id)
      .then((res) => {
        console.log(res.data);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', data.fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        status.druckvorlageErrorMsg = error.response.data.detail;
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.getDruckvorlageLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.CreateDruckvorlage]({ commit, dispatch, rootGetters }, data) {
    const status = rootGetters.status;
    status.createDokumentLoading = true;
    status.druckvorlageErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.createDruckvorlage(data)
      .then((res) => {
        const data: Druckvorlage = res.data;
        commit(MutationTypes.AddDruckvorlage, data);
      })
      .catch((error) => {
        console.log(error);
        status.druckvorlageErrorMsg = 'Es ist ein Fehler aufgetreten';
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createDokumentLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.TestDruckvorlage]({ commit, dispatch, rootGetters }, data) {
    const status = rootGetters.status;
    status.createDokumentLoading = true;
    status.druckvorlageErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.testDruckvorlage(data)
      .then((res) => {
        console.log(res.data);
        // const data: Druckvorlage = res.data
        // commit(MutationTypes.AddDruckvorlage, data)
      })
      .catch((error) => {
        console.log(error);
        status.druckvorlageErrorMsg = 'Es ist ein Fehler aufgetreten';
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.createDokumentLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.UpdateDruckvorlage]({ commit, dispatch, rootGetters }, data) {
    const status = rootGetters.status;
    status.updateDruckvorlageLoading = true;
    status.druckvorlageErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.updateDruckvorlage(data)
      .then((res) => {
        const data: Druckvorlage = res.data;
        commit(MutationTypes.AddOrUpdateDruckvorlage, data);
      })
      .catch((error) => {
        status.druckvorlageErrorMsg = 'Es ist ein Fehler aufgetreten';
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.updateDruckvorlageLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.DeleteDruckvorlage]({ commit, dispatch, rootGetters }, id) {
    const status = rootGetters.status;
    status.deleteDruckvorlageLoading = true;
    status.druckvorlageErrorMsg = '';
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.deleteDruckvorlage(id)
      .then((res) => {
        commit(MutationTypes.DeleteDruckvorlage, id);
      })
      .catch((error) => {
        console.log(error);
        status.druckvorlageErrorMsg = 'Es ist ein Fehler aufgetreten';
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .finally(() => {
        status.deleteDruckvorlageLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },
};
